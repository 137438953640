import React from "react";
import { BasicCollapsible } from "@vp/swan";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { type Flag } from "@shared/utils/Flags";
import { FlagsContents } from "./FlagsContents";

export interface FlagsProps {
    /** Array containing the flag names to check from studio */
    studioFlags: Readonly<Flag[]>;
}

// Since flags should have a limited life span just keep a hardcoded list that gets updated
const allFlags: Readonly<Flag[]> = ["advancedTools"];

export function Flags({ studioFlags }: FlagsProps) {
    const { isSmall } = useStudioLayout();

    // wrap them in a Set to de-dupe any flags
    const flags = Array.from(new Set(allFlags.concat(studioFlags)));
    if (!flags) {
        return null;
    }

    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="flags" heading="Flags">
                    <FlagsContents flags={flags} />
                </BasicCollapsible>
            ) : (
                <ExpandingButton content={<FlagsContents flags={flags} />} showArrow={false}>
                    <span>Flags</span>
                </ExpandingButton>
            )}
        </>
    );
}

Flags.displayName = "Flags";
