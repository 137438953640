import qs from "qs";
import { replaceQueryParam } from "@shared/utils/WebBrowser";
import { useAppSelector } from "@shared/redux";

export function useRestartUrl(locale: string) {
    const productKey = useAppSelector(state => state.productKey);
    const productVersion = useAppSelector(state => state.productVersion);
    const studioSelectedProductOptions = useAppSelector(state => state.studioSelectedProductOptions);
    const quantity = useAppSelector(state => state.quantity);
    const template = useAppSelector(state => state.template);
    const fullBleedElected = useAppSelector(state => state.isFullBleed);

    const paramsObject: any = {
        productVersion,
        key: productKey,
        selectedOptions: JSON.stringify(studioSelectedProductOptions),
        quantity,
        locale,
        template
    };
    if (fullBleedElected) {
        paramsObject.fullBleedElected = true;
    }

    const restartUrlParams = qs.stringify(paramsObject);
    return replaceQueryParam(window.location.href, restartUrlParams);
}
