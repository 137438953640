import React, { useState, useEffect } from "react";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { Typography, FlexBox, Link, BasicCollapsible } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { tryFetch } from "@shared/utils/Network";

const messages = defineMessages({
    templateInformationCopyLabel: {
        id: "studio.ui.debugToolbar.templateInformationCopyLabel",
        defaultMessage: "Copy",
        description: {
            note: "Aria label for a template information"
        }
    }
});

export interface TemplateInformationProps {
    /** The key/sku for the current product */
    templateToken: string;
    locale: string;
    productKey: string;
    productOptions: Record<string, string>;
}

interface GalleryAttributes {
    id: number;
    optionName: string;
    optionValue: string;
    galleryAttribute: string;
}

function getIDTIF(templateToken: string, locale: string): string {
    return `https://cdn.prod.templaterealization.content.cimpress.io/api/v2/templates/${templateToken}/cultures/${locale}/editDocument`;
}

function getGalleryAttributesUrl(productKey: string, productOptions: Record<string, string>): string {
    const stringifiedOptionValues = Object.values(productOptions).reduce((optionValues, option) => {
        return `${optionValues}&optionValues=${encodeURIComponent(option)}`;
    }, "");

    return `https://gallery-content-api.gallery.vpsvc.com/api/v2/OptionMappings?productKey=${productKey}${stringifiedOptionValues}&requestor=StudioDebug`;
}

export function TemplateInformation({ templateToken, locale, productKey, productOptions }: TemplateInformationProps) {
    const [galleryAttributes, setGalleryAttributes] = useState<GalleryAttributes[]>();
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();

    useEffect(() => {
        if (!productKey && !productOptions) {
            return;
        }
        (async () => {
            try {
                const attributes = await tryFetch({
                    url: getGalleryAttributesUrl(productKey, productOptions),
                    options: {
                        method: "GET",
                        headers: {
                            From: "studio",
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    },
                    moduleFunction: "debugToolbar:fetchGalleryAttributes",
                    friendlyDescription: "retrieve gallery attributes",
                    retryCount: 0
                });
                setGalleryAttributes(attributes);
            } catch (e) {
                // intentional do nothing
            }
        })();
    }, [productKey, productOptions]);

    const content = (
        <div className="studio-debug-toolbar-expandable-content">
            <FlexBox justifyContent="space-between">
                <Typography fontWeight="bold" component="span">{`Template: `}</Typography>
                <Link mx={2} target="_blank" href={getIDTIF(templateToken, locale)}>
                    {templateToken}
                </Link>
                <button
                    onClick={() => {
                        navigator.clipboard.writeText(templateToken);
                    }}
                >
                    {t(messages.templateInformationCopyLabel.id)}
                </button>
            </FlexBox>
            <hr />
            <Typography fontWeight="bold">{`Gallery Attribute Mappings`}</Typography>
            {galleryAttributes &&
                galleryAttributes.map(attribute => {
                    return (
                        <div key={attribute.id}>
                            <Typography
                                fontWeight="bold"
                                component="span"
                            >{`${attribute.optionName}[${attribute.optionValue}]: `}</Typography>
                            <Typography component="span" textAlign="right">
                                {attribute.galleryAttribute}
                            </Typography>
                        </div>
                    );
                })}
        </div>
    );

    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="coll-one" heading="Template">
                    {content}
                </BasicCollapsible>
            ) : (
                <ExpandingButton content={content} showArrow={false}>
                    <span>Template</span>
                </ExpandingButton>
            )}
        </>
    );
}

TemplateInformation.displayName = "TemplateInformation";
