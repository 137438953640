import React, { FormEvent } from "react";
import { Typography, Dropdown, DropdownOption } from "@vp/swan";
import { windowExists, getQueryParams, removeQueryParam, addQueryParam } from "@shared/utils/WebBrowser";

const externals = [
    {
        name: "DSS",
        paramName: "dssUrl",
        links: [
            { displayName: "none", url: "none" },
            { displayName: "local", url: "http://localhost:3003" }
        ]
    },
    {
        name: "Designer",
        paramName: "designerUrl",
        links: [
            { displayName: "none", url: "none" },
            { displayName: "local", url: "http://localhost:8080/build/designer.js" }
        ]
    },
    {
        name: "Calcifer",
        paramName: "calciferUrl",
        links: [
            { displayName: "none", url: "none" },
            { displayName: "local", url: "http://localhost:8000" }
        ]
    }
];

export function ExternalLinksContents() {
    if (!windowExists()) {
        return null;
    }

    const onChange = (paramName: string, link: string) => {
        let newUrl = removeQueryParam(window.location.href, paramName);
        if (link !== "none") {
            newUrl = addQueryParam(window.location.href, paramName, link);
        }
        window.location.href = newUrl;
    };

    return (
        <div className="studio-debug-toolbar-expandable-content">
            {externals.map(external => {
                const { name, paramName, links } = external;
                const qp = getQueryParams();

                return (
                    <div key={paramName} className="studio-debug-toolbar-flags">
                        <Typography component="span" marginRight={4}>
                            {name}
                        </Typography>
                        <Dropdown
                            size="mini"
                            value={qp[paramName]}
                            skin="standard"
                            // @ts-ignore
                            onChange={(event: FormEvent<HTMLElement>) => onChange(paramName, event.target.value)}
                        >
                            {links.map(link => {
                                const { displayName, url } = link;
                                return (
                                    <DropdownOption key={displayName} value={url}>
                                        {displayName}
                                    </DropdownOption>
                                );
                            })}
                        </Dropdown>
                    </div>
                );
            })}
        </div>
    );
}

ExternalLinksContents.displayName = "ExternalLinksContents";
