import React, { ReactNode, useState } from "react";
import classNames from "classnames";
import {
    Typography,
    Button,
    Accordion,
    PipeSeparator,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogTitle,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogNav,
    Link
} from "@vp/swan";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { AnimatedDesignTechIcon } from "@shared/features/StudioChrome";
import * as styles from "./DebugToolbar.module.scss";

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: "studio.ui.debugToolbar.ariaLabelClose",
        defaultMessage: "Close debug toolbar dialog",
        description: {
            note: "Aria label for a close button of debug toolbar"
        }
    },
    debugModalAriaLabel: {
        id: "studio.ui.debugToolbar.debugModalAriaLabel",
        defaultMessage: "Debug toolbar dialog",
        description: {
            note: "Aria label of debug toolbar modal content"
        }
    }
});

export interface DebugToolbarProps {
    /**
     * Child elements for toolbar
     */
    children?: ReactNode;
    /** May provide a version to display */
    version?: string;
}

export function DebugToolbar({ children, version }: DebugToolbarProps) {
    const [panelOpen, setPanelOpen] = useState(false);
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();

    return (
        <>
            {isSmall ? (
                <>
                    <Button
                        buttonShape="round"
                        className={classNames("mobile-debug-icon", styles.mobileDebugIcon)}
                        onClick={() => setPanelOpen(true)}
                    >
                        <AnimatedDesignTechIcon />
                    </Button>

                    <LegacyModalDialog isOpen={panelOpen} onRequestDismiss={() => setPanelOpen(false)} takeOver>
                        <LegacyModalDialogContent aria-label={t(messages.debugModalAriaLabel.id)}>
                            <LegacyModalDialogNav>
                                <LegacyModalDialogCloseButton
                                    visuallyHiddenLabel={t(messages.closeButtonAriaLabel.id)}
                                />
                            </LegacyModalDialogNav>
                            <LegacyModalDialogHeader>
                                <LegacyModalDialogTitle>Debug Panel</LegacyModalDialogTitle>
                            </LegacyModalDialogHeader>
                            <LegacyModalDialogBody>
                                <Accordion single skin="standard">
                                    {children}
                                </Accordion>
                            </LegacyModalDialogBody>
                        </LegacyModalDialogContent>
                    </LegacyModalDialog>
                </>
            ) : (
                <div className={classNames("studio-debug-toolbar", styles.toolbar)}>
                    <div className={classNames("studio-debug-toolbar-icon", styles.debugToolbarIcon)}>
                        <AnimatedDesignTechIcon />
                    </div>
                    <Typography fontWeight="bold" component="span">
                        Debug Toolbar
                    </Typography>
                    <PipeSeparator />
                    <Link
                        href="https://vistaprint.atlassian.net/wiki/spaces/DTT/pages/961741064/Debugging%2BStudio%2BErrors"
                        skin="standard"
                        target="_blank"
                    >
                        Need help?
                    </Link>
                    <PipeSeparator />

                    {children}

                    <div className={classNames("studio-debug-toolbar-version", styles.debugToolbarVersion)}>
                        <Typography fontWeight="bold" component="span">
                            Studio Version
                        </Typography>
                        <br />
                        {version}
                    </div>
                </div>
            )}
        </>
    );
}

DebugToolbar.displayName = "DebugToolbar";
