import React from "react";
import { BasicCollapsible } from "@vp/swan";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { ProductInformationContents } from "./ProductInformationContents";

export interface ProductInformationProps {
    /** The key/sku for the current product */
    productKey: string;
    /** Currently selected product options */
    productOptions: Record<string, string>;
    quantity: number;
    productVersion: number | undefined;
    getCurrentVersion: (productKey: string) => Record<string, any>;
    mcpSku: string;
    locale: string;
}

export function ProductInformation({
    productKey,
    productOptions,
    quantity,
    productVersion,
    getCurrentVersion,
    mcpSku,
    locale
}: ProductInformationProps) {
    const { isSmall } = useStudioLayout();

    const productInfo = (
        <ProductInformationContents
            productKey={productKey}
            productOptions={productOptions}
            quantity={quantity}
            productVersion={productVersion}
            getCurrentVersion={getCurrentVersion}
            mcpSku={mcpSku}
            locale={locale}
        />
    );

    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="productInformation" heading="Product Information">
                    {productInfo}
                </BasicCollapsible>
            ) : (
                <ExpandingButton content={productInfo} showArrow={false}>
                    <span>Product Info</span>
                </ExpandingButton>
            )}
        </>
    );
}

ProductInformation.displayName = "ProductInformation";
