import React from "react";
import { Typography, SelectionSet, SelectionSetInput, SelectionSetLabel } from "@vp/swan";
import { localeData } from "./LinkUtils";

interface Props {
    /** The option value from a user selection or default value, can also be empty. */
    selectedLocale?: string;
    /** Will return the new selected option value. */
    onSelectLocale(locale: string): void;
}
export function LocalePulldownList({ selectedLocale, onSelectLocale }: Props) {
    return (
        <div>
            <SelectionSet
                variant="single-select"
                name="LocaleSelector"
                skin="tiles-mini"
                selectedValue={selectedLocale}
                onSelectedValueChange={onSelectLocale}
            >
                {Object.entries(localeData).map(([locale, { name }]) => {
                    return (
                        <SelectionSetInput key={`locale-selector-${locale}`} value={locale}>
                            <SelectionSetLabel className="locale-select-list-label">
                                <Typography fontWeight="bold" textAlign="left">
                                    {locale}
                                </Typography>
                                <Typography fontWeight="bold" textAlign="right" marginLeft="auto" noWrap>
                                    {name}
                                </Typography>
                            </SelectionSetLabel>
                        </SelectionSetInput>
                    );
                })}
            </SelectionSet>
        </div>
    );
}
LocalePulldownList.displayName = "LocalePulldownList";
