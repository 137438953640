import React from "react";
import { BasicCollapsible } from "@vp/swan";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { TestLinksContent } from "./TestLinksContent";

export interface TestLinksProps {
    locale: string;
}

export function TestLinks(props: TestLinksProps) {
    const { locale } = props;
    const { isSmall } = useStudioLayout();

    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="testLinks" heading="Test Links">
                    <TestLinksContent locale={locale} />
                </BasicCollapsible>
            ) : (
                <ExpandingButton content={<TestLinksContent locale={locale} />}>
                    <span>Test Links</span>
                </ExpandingButton>
            )}
        </>
    );
}

TestLinks.displayName = "TestLinks";
