import React, { useState, useEffect } from "react";
import { ToggleSwitch, Box, ToggleSwitchOnLabel, ToggleSwitchOffLabel } from "@vp/swan";

import { disableDebugMode } from "@shared/utils/Debug";

export const DebugToolbarToggle = () => {
    const [activated, setActivated] = useState(true);

    useEffect(() => {
        if (!activated) {
            disableDebugMode();
        }
    }, [activated]);
    return (
        <Box component="div" ml={2} style={{ display: "inline-block" }}>
            <ToggleSwitch
                style={{ backgroundColor: "#f6f7f8" }}
                activated={activated}
                onRequestActivatedChange={() => setActivated(false)}
            >
                <ToggleSwitchOnLabel>Debug On</ToggleSwitchOnLabel>
                <ToggleSwitchOffLabel>Debug Off</ToggleSwitchOffLabel>
            </ToggleSwitch>
        </Box>
    );
};

DebugToolbarToggle.displayName = "DebugToolbarToggle";
