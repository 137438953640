/* eslint-disable max-len */
export interface TestLink {
    name: string;
    link: string;
    locales: string[];
    details?: string;
}

const EU = ["ie", "pt", "gb", "fr", "be", "es", "it", "de", "se", "at", "fi", "no", "ch"];
const NA = ["ca", "us"];
const ANZ = ["au", "nz", "sg"];
const IN = ["in"];

const fullBleedDetails =
    "Full Bleed templates often involve unique prompts for users and slightly different entry flows";
const imagePlaceholderBcDetails =
    "A business card with an image placeholder is highly representative of an average customer design";
const metallicFoilDetails =
    "Premium finishes like metallic foil have unique options for customers and UI presentations of the document";
const spotUvDetails = 'spot UV premium finishes add unique functionality via the "Finishes" tool';
const goldFoilDetails =
    'Gold foil embossing can be applied to specific items and has a unique rendering. Gold foil products have access to the "Finishes" tool';
const bannerDetails = "Banners have extreme aspect ratios that expose the limits of the canvas's layout";
const tShirtDetails =
    't-shirts include an underlay for the shirt. This document has a large designable area so image sharpening functionality is used. Apparel products often include a color selector for "Material"';
const singleColorTShirtDetails =
    "Single Color t-shirts restricts all items to be the same color, including forcing multi-colored images to a single color.";
const hatDetails =
    'Hats expose functionalities related to embroidery, including custom rendering of items and new modals in the user flow. Hats also expose the "Team" features';
const poloDetails = "Polo shirts expose embroidery features, comparable to a hat, but with a larger designable area";
const engravedPenDetails =
    "Engraved pens have an extreme aspect ratio, an underlay, and conversion of uploads to a single color";
const notebookDetails = "Notebooks have more panels than normal, forcing unique UI related to panel selection";
const postcardDetails =
    "Postcards are a simple product, comparable to business cards. Upsells to mailing services are available in certain locales";
export const TestLinkData: TestLink[] = [
    // Standard BC - Full Bleed
    {
        name: "Standard BC - Full Bleed (EU)",
        link: `key=PRD-RWLCHPA0&mpvId=standardBusinessCards&qty=250&selectedOptions=%7B"Cut+Shape+Type"%3A"Rectangle"%2C"Finish"%3A"None"%2C"Product+Orientation"%3A"Horizontal"%2C"Size"%3A"Standard+%2885+x+55+mm%29"%7D&fullBleedElected=true`,
        locales: EU,
        details: fullBleedDetails
    },
    {
        name: "Standard BC - Full Bleed (ANZ)",
        link: `key=PRD-GIDUKALA&selectedOptions=%7B"Product+Orientation"%3A"Landscape"%7D&mpvId=standardBusinessCards&template=&previewUrl=&qty=250&fullBleedElected=true&description=Horizontal`,
        locales: ANZ,
        details: fullBleedDetails
    },
    {
        name: "Standard BC - Full Bleed (NA)",
        link: `key=PRD-IYXT1T3V&selectedOptions=%7B"Product+Orientation"%3A"Horizontal"%2C"Shape"%3A"Standard"%7D&mpvId=standardBusinessCards&template=&previewUrl=&qty=100&fullBleedElected=true&description=Horizontal`,
        locales: NA,
        details: fullBleedDetails
    },
    {
        name: "Standard BC - Full Bleed (IN)",
        link: `key=PRD-YN48WWE9&locale=en-in&selectedOptions=%7B"Product+Orientation"%3A"Horizontal"%7D&fullBleedElected=true&mpvId=standardBusinessCards&description=Horizontal`,
        locales: IN,
        details: fullBleedDetails
    },
    // Standard BC - Image Placeholder
    {
        name: "Standard BC - Image Placeholder (EU)",
        link: `key=PRD-RWLCHPA0&mpvId=standardBusinessCards&qty=250&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"Standard%20%2885%20x%2055%20mm%29"%2C"Cut%20Shape%20Type"%3A"Rectangle"%2C"Finish"%3A"None"%7D&template=c3288158..a0bd0bb1-9801-42f7-93bf-fe35649206a0`,
        locales: EU,
        details: imagePlaceholderBcDetails
    },
    {
        name: "Standard BC - Image Placeholder (ANZ)",
        link: `key=PRD-GIDUKALA&mpvId=standardBusinessCards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3796964..c268fe7b-3c71-4a06-9f3e-3e47227bef55%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3796964..c268fe7b-3c71-4a06-9f3e-3e47227bef55%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=250&selectedOptions=%7B"Format"%3A"%2891x55%29"%2C"SpecialFinishing"%3A"None"%2C"Product%20Orientation"%3A"Landscape"%2C"CutShape"%3A"Rectangle"%7D&template=c3796964..c268fe7b-3c71-4a06-9f3e-3e47227bef55`,
        locales: ANZ,
        details: imagePlaceholderBcDetails
    },
    {
        name: "Standard BC - Image Placeholder (NA)",
        link: `key=PRD-IYXT1T3V&mpvId=standardBusinessCards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3288326..f9c58b2a-e04f-4251-a983-6eb421ba478d%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3288326..f9c58b2a-e04f-4251-a983-6eb421ba478d%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=100&selectedOptions=%7B"Finish"%3A"None"%2C"Shape"%3A"Standard"%2C"Corners"%3A"Standard%20Corners"%2C"Product%20Orientation"%3A"Horizontal"%7D&template=c3288326..f9c58b2a-e04f-4251-a983-6eb421ba478d`,
        locales: NA,
        details: imagePlaceholderBcDetails
    },
    {
        name: "Standard BC - Image Placeholder (IN)",
        link: `key=PRD-YN48WWE9&locale=en-in&metadata=%7B"galleryEngagementId"%3A"0fe45195-82d3-4593-8fb3-52b97b71793b"%2C"galleryImpressionId"%3A"0fe45195-82d3-4593-8fb3-52b97b71793b"%7D&mpvId=standardBusinessCards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3288326..f9c58b2a-e04f-4251-a983-6eb421ba478d%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3288326..f9c58b2a-e04f-4251-a983-6eb421ba478d%25252Fcultures%25252Fen-in%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=100&selectedOptions=%7B"Size"%3A"Standard%20%2889%20x%2051%20mm%29"%2C"Cut%20Shape%20Type"%3A"Rectangle"%2C"Finish"%3A"None"%2C"Product%20Orientation"%3A"Horizontal"%7D&template=c3288326..f9c58b2a-e04f-4251-a983-6eb421ba478d`,
        locales: IN,
        details: imagePlaceholderBcDetails
    },
    // Metallic BC - Metal Foil - Currently not available in ANZ
    {
        name: "Metallic BC - Metal Foil (EU)",
        link: `key=PRD-X0KWG5Y8&mpvId=metallicBusinessCards&qty=250&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"Standard%20%2885%20x%2055%20mm%29"%2C"Finish"%3A"Metallic"%7D&template=c6162654..a0bd0bb1-9801-42f7-93bf-fe35649206a0`,
        locales: EU,
        details: metallicFoilDetails
    },
    {
        name: "Full Bleed Metallic BC - Metal Foil (EU)",
        link: `key=PRD-X0KWG5Y8&selectedOptions=%7B"Product+Orientation"%3A"Vertical"%2C"Size"%3A"Standard+%2885+x+55+mm%29"%7D&mpvId=metallicBusinessCards&template=&previewUrl=&qty=250&fullBleedElected=true&description=Vertical`,
        locales: EU,
        details: metallicFoilDetails
    },
    {
        name: "Metallic BC - Foil Accent (NA)",
        link: `key=PRD-CRHN2BKI&mpvId=foilAccentBusinessCards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6162674..f9c58b2a-e04f-4251-a983-6eb421ba478d%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6162674..f9c58b2a-e04f-4251-a983-6eb421ba478d%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=100&selectedOptions=%7B"Finish"%3A"Foil%20Accent"%2C"Corners"%3A"Standard%20Corners"%2C"Product%20Orientation"%3A"Horizontal"%7D&template=c6162674..f9c58b2a-e04f-4251-a983-6eb421ba478d`,
        locales: NA,
        details: metallicFoilDetails
    },
    // Spot UV BC - Raised Ink
    {
        name: "Spot UV BC - Raised Ink (EU)",
        link: `key=PRD-WBY4DECX&mpvId=spotUVBusinessCards&qty=250&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"Standard%20%2885%20x%2055%20mm%29"%2C"Cut%20Shape%20Type"%3A"Rectangle"%2C"Finish"%3A"Spot%20UV"%7D&template=c6246071..a0bd0bb1-9801-42f7-93bf-fe35649206a0`,
        locales: EU,
        details: spotUvDetails
    },
    {
        name: "Spot UV BC - Raised Ink (ANZ)",
        link: `key=PRD-JKENF4EI&mpvId=spotUVBusinessCards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6246035..c268fe7b-3c71-4a06-9f3e-3e47227bef55%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6246035..c268fe7b-3c71-4a06-9f3e-3e47227bef55%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=250&selectedOptions=%7B"Format"%3A"%2891x55%29"%2C"SpecialFinishing"%3A"Single%20Sided%20Spot%20UV%20Varnish"%2C"Product%20Orientation"%3A"Landscape"%2C"CutShape"%3A"Rectangle"%7D&template=c6246035..c268fe7b-3c71-4a06-9f3e-3e47227bef55`,
        locales: ANZ,
        details: spotUvDetails
    },
    {
        name: "Spot UV BC - Embossed Gloss (NA)",
        link: `key=PRD-WJIDFUBS&mpvId=embossedGlossBusinessCards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6246063..f9c58b2a-e04f-4251-a983-6eb421ba478d%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6246063..f9c58b2a-e04f-4251-a983-6eb421ba478d%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=100&selectedOptions=%7B"Finish"%3A"Embossed%20Gloss"%2C"Corners"%3A"Standard%20Corners"%2C"Product%20Orientation"%3A"Horizontal"%7D&template=c6246063..f9c58b2a-e04f-4251-a983-6eb421ba478d`,
        locales: NA,
        details: spotUvDetails
    },
    // Todo: Expand out for other locales
    {
        name: "Folded Holiday Card (IE/UK)",
        link: `key=PRD-8JTK1GHE&mpvId=ukieChristmasCard&selectedOptions=%7B"Product%20Orientation"%3A"Vertical"%2C"Stock"%3A"Linen%2013.4pt"%2C"Size"%3A"5.5%20x%205.5in"%7D&template=c6041443..f176f8d3-d98c-4a89-953b-95ec427ccf56`,
        locales: ["ie", "gb"],
        details: "Folded cards display masks for fold lines and additional panels."
    },
    // Gold Foil Invitation
    {
        name: "Gold Foil Invitation (EU)",
        link: `key=PRD-FWY9GXHO&mpvId=invitationAnnouncement&selectedOptions=%7B"Product%20Orientation"%3A"Vertical"%2C"Corner"%3A"Standard"%2C"Size"%3A"5%20x%207in"%2C"Fold"%3A"Flat"%2C"Foil%20Color"%3A"Gold"%7D&template=c4055352..449df25f-ce48-4a23-9804-4a2911165000`,
        locales: EU,
        details: goldFoilDetails
    },
    {
        name: "Gold Foil Invitation (ANZ)",
        link: `key=PRD-ATVIPGXC&mpvId=invitationAnnouncement&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc4055352..449df25f-ce48-4a23-9804-4a2911165000%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc4055352..449df25f-ce48-4a23-9804-4a2911165000%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DTrue%252526finishType%25253DRaisedFoilGold%2526type%253Dpreview&qty=10&selectedOptions=%7B"Foil%20Color"%3A"Gold"%2C"Fold"%3A"Flat"%2C"Size"%3A"5%20x%207in"%2C"Product%20Orientation"%3A"Vertical"%2C"Corner"%3A"Standard"%7D&template=c4055352..449df25f-ce48-4a23-9804-4a2911165000`,
        locales: ANZ,
        details: goldFoilDetails
    },
    {
        name: "Gold Embossed Foil Invitation (NA)",
        link: `key=PRD-MOIKO8CZ&mpvId=weddingInvitations&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc4055352..449df25f-ce48-4a23-9804-4a2911165000%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc4055352..449df25f-ce48-4a23-9804-4a2911165000%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DTrue%252526finishType%25253DRaisedFoilGold%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fassets.documents.cimpress.io%2Fv3%2Fassets%2F893d4a49-c3f2-4c07-b4a3-f8dba1d3a1b0%2Fcontent&selectedOptions=%7B"Size"%3A"4.6%5Cu0022%20x%207.2%5Cu0022"%2C"Fold"%3A"Flat"%2C"Trim"%3A"Standard"%2C"Foil%20Color"%3A"Gold"%2C"Product%20Orientation"%3A"Vertical"%7D&template=c4055352..449df25f-ce48-4a23-9804-4a2911165000`,
        locales: NA,
        details: goldFoilDetails
    },
    // Vinyl Banner - Super Long
    {
        name: "Vinyl Banner - Super Long (EU)",
        link: `key=PRD-LT1L2KI8J&mpvId=euVinylBanners&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3996111..4240de62-8385-4e99-9f91-cd03b789ff44%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3996111..4240de62-8385-4e99-9f91-cd03b789ff44%25252Fcultures%25252Fen-ie%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=1&selectedOptions=%7B"Material"%3A"Reinforced%20Vinyl"%2C"Size"%3A"76%20x%20366%20cm"%2C"Fastener%20Type"%3A"Metal%20Grommets"%2C"Product%20Orientation"%3A"Vertical"%2C"Pole%20Pocket"%3A"No"%7D&template=c3996111..4240de62-8385-4e99-9f91-cd03b789ff44`,
        locales: EU,
        details: bannerDetails
    },
    {
        name: "Vinyl Banner - Super Long (ANZ)",
        link: `key=PRD-LRNA8EOQ&mpvId=vinylBanners&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3996111..4240de62-8385-4e99-9f91-cd03b789ff44%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3996111..4240de62-8385-4e99-9f91-cd03b789ff44%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=1&selectedOptions=%7B"Size"%3A"2.5x12%20ft%20%2876x366%20cm%29"%2C"Material"%3A"Indoor%20Vinyl"%2C"Reinforcements"%3A"Weldings"%2C"Product%20Orientation"%3A"Vertical"%2C"Fastener%20Type"%3A"Plastic%20Grommets"%7D&template=c3996111..4240de62-8385-4e99-9f91-cd03b789ff44`,
        locales: ANZ,
        details: bannerDetails
    },
    {
        name: "Vinyl Banner - Super Long (NA)",
        link: `key=PRD-XKHDONHL&mpvId=vinylBanners&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3962103..c874f40c-27cf-4112-84cf-0fa524dc93f1%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3962103..c874f40c-27cf-4112-84cf-0fa524dc93f1%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fgallery-scenes.gallery.vpsvc.com%2FGalleryScenes%2FBanner434%2FVertical25x12%2F1498.xml&qty=1&selectedOptions=%7B"Product%20Orientation"%3A"Vertical"%2C"Size"%3A"2.5%5Cu0027%20x%2012%5Cu0027"%2C"Material%20Type"%3A"Indoor%20with%20Reinforced%20Edges"%7D&template=c3962103..c874f40c-27cf-4112-84cf-0fa524dc93f1`,
        locales: NA,
        details: bannerDetails
    },
    // Vinyl Banner - Super Wide
    {
        name: "Vinyl Banner - Super Wide (EU)",
        link: `key=PRD-LT1L2KI8J&mpvId=euVinylBanners&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3998086..f5f8b09a-aebd-43b6-bb16-dd1fe022c02b%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3998086..f5f8b09a-aebd-43b6-bb16-dd1fe022c02b%25252Fcultures%25252Fen-ie%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=1&selectedOptions=%7B"Material"%3A"Reinforced%20Vinyl"%2C"Size"%3A"76%20x%20366%20cm"%2C"Fastener%20Type"%3A"Metal%20Grommets"%2C"Product%20Orientation"%3A"Horizontal"%2C"Pole%20Pocket"%3A"No"%7D&template=c3998086..f5f8b09a-aebd-43b6-bb16-dd1fe022c02b`,
        locales: EU,
        details: bannerDetails
    },
    {
        name: "Vinyl Banner - Super Wide (ANZ)",
        link: `key=PRD-LRNA8EOQ&mpvId=vinylBanners&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3208983..07e6b6f3-14bc-4943-a4d0-632c0736d483%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3208983..07e6b6f3-14bc-4943-a4d0-632c0736d483%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=1&selectedOptions=%7B"Size"%3A"2.5x12%20ft%20%2876x366%20cm%29"%2C"Material"%3A"Indoor%20Vinyl"%2C"Reinforcements"%3A"No"%2C"Product%20Orientation"%3A"Horizontal"%7D&template=c3208983..07e6b6f3-14bc-4943-a4d0-632c0736d483`,
        locales: ANZ,
        details: bannerDetails
    },
    {
        name: "Vinyl Banner - Super Wide (NA)",
        link: `key=PRD-XKHDONHL&mpvId=vinylBanners&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc3208983..07e6b6f3-14bc-4943-a4d0-632c0736d483%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc3208983..07e6b6f3-14bc-4943-a4d0-632c0736d483%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fgallery-scenes.gallery.vpsvc.com%2FGalleryScenes%2FBanner434%2FHorizontal25x12%2F1497.xml&qty=1&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"2.5%5Cu0027%20x%2012%5Cu0027"%2C"Material%20Type"%3A"Indoor%20with%20Reinforced%20Edges"%7D&template=c3208983..07e6b6f3-14bc-4943-a4d0-632c0736d483`,
        locales: NA,
        details: bannerDetails
    },
    // T-Shirt
    {
        name: "T-Shirt (EU)",
        link: `key=PRD-CYQWCTQS&mpvId=premiumTShirt&qty=1&selectedOptions=%7B"Gender"%3A"Men"%2C"Front"%3A"Full%20front"%2C"Material%20Weight"%3A"175%20g%20m2"%2C"Substrate%20Color"%3A"%23ffffff"%2C"Sleeve%20Style"%3A"Short%20Sleeve"%7D&template=c5656173..985a77d7-82be-4d10-8e87-45b1f8a16e04`,
        locales: EU,
        details: tShirtDetails
    },
    {
        name: "T-Shirt (ANZ)",
        link: `key=PRD-W2JOH1U9&mpvId=standardGildanTShirts&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc5656173..985a77d7-82be-4d10-8e87-45b1f8a16e04%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc5656173..985a77d7-82be-4d10-8e87-45b1f8a16e04%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fs3-eu-west-1.amazonaws.com%2Fgallery-scenes.gallery.vpsvc.com%2FGalleryScenes%2FKidsTShirtsEU398%2FWhiteFullFront%2F974.xml&qty=1&selectedOptions=%7B"Substrate%20Color"%3A"%23FFFFFF"%2C"Gender"%3A"Men"%2C"Size"%3A"2XL"%2C"Sleeve%20Style"%3A"Short%20Sleeve"%2C"Material%20Weight"%3A"150"%2C"Front"%3A"Full%20front"%7D&template=c5656173..985a77d7-82be-4d10-8e87-45b1f8a16e04`,
        locales: ANZ,
        details: tShirtDetails
    },
    {
        name: "T-Shirt (NA)",
        link: `key=PRD-IWMH1LKZ&mpvId=caBasicTShirts&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc5656171..985a77d7-82be-4d10-8e87-45b1f8a16e04%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc5656171..985a77d7-82be-4d10-8e87-45b1f8a16e04%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fassets.documents.cimpress.io%2Fv3%2Fassets%2F484ce89a-5328-439d-8db1-69d24cdf9664%2Fcontent&qty=1&selectedOptions=%7B"Substrate%20Color"%3A"%231D4F91"%2C"Decoration%20Technology"%3A"Full%20Color"%2C"Decoration%20Area"%3A"Full%20Front"%7D&template=c5656171..985a77d7-82be-4d10-8e87-45b1f8a16e04`,
        locales: NA,
        details: tShirtDetails
    },
    // T-shirt w/ both alt meterial & template colors
    {
        name: "T-Shirt (EU) with both alt colors",
        link: `key=PRD-CYQWCTQS&mpvId=premiumTShirt&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc4498174..767d635e-c9d1-480c-be31-bd7c1f919eda%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc4498174..767d635e-c9d1-480c-be31-bd7c1f919eda%25252Fcultures%25252Fen-ie%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fgallery-scenes.gallery.vpsvc.com%2FGalleryScenes%2FPremiumTShirtsEU415%2FBlackFullFront%2F1211.xml&qty=1&selectedOptions=%7B"Substrate%20Color"%3A"%2325282a"%2C"Gender"%3A"Men"%2C"Sleeve%20Style"%3A"Short%20Sleeve"%2C"Material%20Weight"%3A"175%20g%20m2"%2C"Front"%3A"Full%20front"%7D&template=c4498174..767d635e-c9d1-480c-be31-bd7c1f919eda`,
        locales: EU,
        details: tShirtDetails
    },
    {
        name: "T-Shirt (ANZ) with both alt colors",
        link: `key=PRD-B63H6BVG&mpvId=gildanMensTShirt&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc4498174..767d635e-c9d1-480c-be31-bd7c1f919eda%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc4498174..767d635e-c9d1-480c-be31-bd7c1f919eda%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fassets.documents.cimpress.io%2Fv3%2Fassets%2Fdd2fa388-91a6-4b74-a68f-fb4b7a4aa8a7%2Fcontent&qty=1&selectedOptions=%7B"Color"%3A"%23000000"%2C"Front"%3A"Full%20Front"%7D&template=c4498174..767d635e-c9d1-480c-be31-bd7c1f919eda`,
        locales: ANZ,
        details: tShirtDetails
    },
    {
        name: "T-Shirt (NA) with both alt colors",
        link: `key=PRD-RU50RIWPS&mpvId=mensPremiumShortSleeveTShirts&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc4498174..767d635e-c9d1-480c-be31-bd7c1f919eda%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc4498174..767d635e-c9d1-480c-be31-bd7c1f919eda%25252Fcultures%25252Fen-us%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fassets.documents.cimpress.io%2Fv3%2Fassets%2F3e1ade43-0a91-4429-aa49-96140fd3fb17%2Fcontent&qty=1&selectedOptions=%7B"Substrate%20Color"%3A"%23000000"%2C"Deco%20Area"%3A"Full%20Front"%2C"Deco%20Tech"%3A"Full%20Color"%7D&template=c4498174..767d635e-c9d1-480c-be31-bd7c1f919eda`,
        locales: NA,
        details: tShirtDetails
    },
    // Hat - multi color substrate
    {
        name: "Hat (EU) with multi color substrate",
        link: `key=PRD-C7E9EBB0&selectedOptions=%7B"Substrate+Color"%3A"%2320583b"%7D&fullBleedElected=true&mpvId=solsBuffaloCap&qty=`,
        locales: EU,
        details: hatDetails
    },
    {
        name: "Hat (ANZ) with multi color substrate",
        link: `key=PRD-P0EAYRLYR&selectedOptions=%7B%22Substrate+Color%22%3A%22%23343434%2F%23ffc626%22%7D&fullBleedElected=true&mpvId=blackSwiftCap_ANZ&qty=`,
        locales: ANZ,
        details: hatDetails
    },
    {
        name: "Hat (NA) with multi color substrate",
        link: `key=PRD-6EG3UODEA&selectedOptions=%7B"Substrate+Color"%3A"%234f4d52%2F%238b152b"%7D&fullBleedElected=true&mpvId=sportsmanTriColorCapNa&qty=`,
        locales: NA,
        details: hatDetails
    },
    // Pocket stamps
    {
        name: "Pocket stamps (EU)",
        link: `key=PRD-E4AKJJYZ&locale=en-ie&mpvId=pocketStamps&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6079176..56e994b5-3044-4f6f-8a08-27e183f15abc%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6079176..56e994b5-3044-4f6f-8a08-27e183f15abc%25252Fcultures%25252Fen-ie%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=1&selectedOptions=%7B%22Stamp%20Style%22%3A%22Pocket%22%2C%22Size%22%3A%221.42%20x%200.47%20in%22%2C%22Decoration%20Location%201%20Engraving%20Color%22%3A%22%23000000%22%7D&template=c6079176..56e994b5-3044-4f6f-8a08-27e183f15abc`,
        locales: EU,
        details: "Pocket Stamps are a monochrome product where color selection should never be possible"
    },
    // Single color T-Shirt - Currently not in ANZ, IN
    {
        name: "Single color T-Shirt (EU)",
        link: `key=PRD-HGL2NPXJ&selectedOptions=%7B"Front"%3A"Full+front"%2C"Printing+Process+Decoration+Location+1"%3A"Screenprint"%2C"Sleeve+Style"%3A"Short+Sleeve"%7D&mpvId=basicTShirts&template=&previewUrl=&qty=5&fullBleedElected=true&description=Design+area%3A+Full+front`,
        locales: EU,
        details: singleColorTShirtDetails
    },
    {
        name: "Single color T-Shirt (NA)",
        link: `key=PRD-MZAOM37E1&productVersion=13&locale=en-us&selectedOptions=%7B"Substrate+Color"%3A"%23000000"%2C"Decoration"%3A"Single+Color"%7D&fullBleedElected=true&mpvId=longSleeveMensTShirt&qty=1`,
        locales: NA,
        details: singleColorTShirtDetails
    },
    // Polo - Embroidery
    {
        name: "Polo - Embroidery (EU)",
        link: `key=PRD-NTGDU86U&mpvId=embroideredPolos&qty=1&selectedOptions=%7B%22Gender%22%3A%22Mens%27%22%2C%22Substrate%20Color%22%3A%22%23FFFFFF%22%7D`,
        locales: EU,
        details: poloDetails
    },
    {
        name: "Polo - Embroidery (ANZ)",
        link: `key=PRD-WYXJ4PJA&mpvId=embroideredPolos&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc2690176..2a6e9b03-5f61-40bf-a9f1-19fa36a07183%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc2690176..2a6e9b03-5f61-40bf-a9f1-19fa36a07183%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttp%3A%2F%2Fwww.vistaprint.com%2Fdocuments%2Fapi%2Fscene%2F561&qty=1&selectedOptions=%7B"Gender"%3A"Mens%5Cu0027"%2C"Substrate%20Color"%3A"%23FFFFFF"%7D&template=c2690176..2a6e9b03-5f61-40bf-a9f1-19fa36a07183`,
        locales: ANZ,
        details: poloDetails
    },
    {
        name: "Polo - Embroidery (NA)",
        link: `key=PRD-Q5NSNHLL&mpvId=jerzeesPolo&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc2690176..2a6e9b03-5f61-40bf-a9f1-19fa36a07183%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc2690176..2a6e9b03-5f61-40bf-a9f1-19fa36a07183%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fassets.documents.cimpress.io%2Fv3%2Fassets%2F1c861781-eec6-4301-add8-3000374be882%2Fcontent&qty=1&selectedOptions=%7B"Gender"%3A"Men%5Cu0027s"%2C"Substrate%20Color"%3A"%23FFFFFF"%7D&template=c2690176..2a6e9b03-5f61-40bf-a9f1-19fa36a07183`,
        locales: NA,
        details: poloDetails
    },
    // Engraved Pen - Not available in ANZ
    {
        name: "Premium Engraved Pen (EU)",
        link: `key=PRD-IWOY4D1F&mpvId=premiumEngravedPens&qty=1&selectedOptions=%7B%7D&fullBleedElected=true`,
        locales: EU,
        details: engravedPenDetails
    },
    {
        name: "Engraved Pen (NA)",
        link: `key=PRD-H1ZUN4GZ&selectedOptions=%7B"Substrate%20Color"%3A"%23000000"%7D&mpvId=radiantBallpointPen&qty=1&fullBleedElected=true`,
        locales: NA,
        details: engravedPenDetails
    },
    // Notebooks
    {
        name: "Notebooks (EU)",
        link: `key=PRD-JT5DAOXL&mpvId=notebooks&selectedOptions=%7B"Filler%20Paper"%3A"Lined"%7D&template=c2869096..8d07a189-0406-4e84-8881-06494d790adc`,
        locales: EU,
        details: notebookDetails
    },
    {
        name: "Notebooks (NA)",
        link: `key=PRD-G3SQ16F8&mpvId=notebooks&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc2869096..8d07a189-0406-4e84-8881-06494d790adc%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc2869096..8d07a189-0406-4e84-8881-06494d790adc%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fassets.documents.cimpress.io%2Fv3%2Fassets%2Fc34361e8-34cb-468e-8c4f-afd4c53daf44%2Fcontent&qty=1&selectedOptions=%7B%7D&template=c2869096..8d07a189-0406-4e84-8881-06494d790adc`,
        locales: NA,
        details: notebookDetails
    },
    // Postcards
    {
        name: "Postcards (US)",
        link: `key=PRD-ENRS4XZIS&locale=en-us&mpvId=postcards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf%25252Fcultures%25252Fen-us%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=50&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"4.2%5Cu0022%20x%205.5%5Cu0022"%2C"Paper%20Thickness"%3A"Premium"%2C"Paper%20Stock"%3A"Glossy%20%28front%20only%29"%2C"Mailing%20Services"%3A"Not%20Mailable"%2C"Postage"%3A"Not%20Applicable"%2C"Address%20Service%20Option"%3A"Not%20Applicable"%7D&template=c6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf`,
        locales: ["us"],
        details: postcardDetails
    },
    {
        name: "Mailing services - Postcards (US)",
        link: `key=PRD-WHVSX0JNJ&locale=en-us&mpvId=mailingServicesPostcard&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf%25252Fcultures%25252Fen-us%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=50&selectedOptions=%7B"Address%20Service%20Option"%3A"Current%20Resident"%2C"Backside"%3A"Blank"%2C"Mailing%20Services"%3A"Mailable"%2C"Paper%20Stock"%3A"Glossy%20%28front%20only%29"%2C"Paper%20Thickness"%3A"Premium"%2C"Postage"%3A"First%20Class"%2C"Processing"%3A"Rush"%2C"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"4.2%5C"%20x%205.5%5C""%7D&template=c6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf`,
        locales: ["us"],
        details:
            "Mailing Service Postcards have unique behaviors including masking on the backside, validations related to positioning items near that mask, and user prompts for the mailing service flow"
    },
    {
        name: "Postcards (CA)",
        link: `key=PRD-JHBKMJNB&locale=en-ca&mpvId=postcards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf%25252Fcultures%25252Fen-ca%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=500&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"4.2%5Cu0022%20x%205.5%5Cu0022"%2C"Paper%20Thickness"%3A"Premium"%2C"Paper%20Stock"%3A"Glossy%20%28front%20only%29"%7D&template=c6623356..d7374301-c641-4b32-9adf-160a2b6c7eaf`,
        locales: ["ca"],
        details: postcardDetails
    },
    {
        name: "Postcards (EU)",
        link: `key=PRD-LCOISZBH&mpvId=postcards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6623276..72668029-39c9-44fb-a23d-799404840b2d%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6623276..72668029-39c9-44fb-a23d-799404840b2d%25252Fcultures%25252Fen-gb%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview%26scene%3Dhttps%3A%2F%2Fs3-eu-west-1.amazonaws.com%2Fgallery-scenes.gallery.vpsvc.com%2FGalleryScenes%2FPostcards369%2FA5SquareMatteHorizontal%2F4371.xml&qty=1000&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"A5"%2C"Thickness"%3A"Premium"%2C"Cut%20Shape%20Type"%3A"Rectangle"%7D&template=c6623276..72668029-39c9-44fb-a23d-799404840b2d`,
        locales: EU,
        details: postcardDetails
    },
    {
        name: "Postcards (ANZ)",
        link: `key=PRD-HBTR1ZFR&mpvId=postcards&previewUrl=%2F%2Frendering.mcp.cimpress.com%2Fv1%2Fvp%2Fpreview%3Fwidth%3D200%26category%3Dgp%26format%3Djpeg%26quality%3D85%26merchant_metadata%3Dc6623276..72668029-39c9-44fb-a23d-799404840b2d%26instructions_uri%3Dhttp%253A%252F%252Fuds.documents.cimpress.io%252Fv2%252Ftransient%252Fvp%253FdocumentUri%253Dhttp%25253A%25252F%25252Fprod.templaterealization.content.cimpress.io%25252Fapi%25252Fv2%25252Ftemplates%25252Fc6623276..72668029-39c9-44fb-a23d-799404840b2d%25252Fcultures%25252Fen-nz%25252FrenderDocument%25253FuseFakeFamily%25253DFalse%252526finishType%25253DNone%2526type%253Dpreview&qty=50&selectedOptions=%7B"Product%20Orientation"%3A"Horizontal"%2C"Size"%3A"A5"%2C"Cut%20Shape%20Type"%3A"Rectangle"%7D&template=c6623276..72668029-39c9-44fb-a23d-799404840b2d`,
        locales: ANZ,
        details: postcardDetails
    }
];
