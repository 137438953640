import React, { useState, useCallback } from "react";
import { ToggleSwitch, ToggleSwitchOnLabel, ToggleSwitchOffLabel } from "@vp/swan";
import { type Flag, isFlagEnabled, toggleFlag } from "@shared/utils/Flags";

interface Props {
    /** Array containing the flag names to check */
    flags: Readonly<Flag[]>;
}

export function FlagsContents({ flags }: Props) {
    // our 'state' is actually in local storage, so just force a rerender
    const [, updateState] = useState<{}>();
    const forceUpdate = useCallback(() => updateState({}), []);

    if (!flags) {
        return null;
    }

    return (
        <div className="studio-debug-toolbar-expandable-content">
            {flags.map(flag => {
                return (
                    <div key={flag} className="studio-debug-toolbar-flags">
                        <ToggleSwitch
                            style={{ backgroundColor: "#f6f7f8" }}
                            activated={isFlagEnabled(flag)}
                            onRequestActivatedChange={() => {
                                toggleFlag(flag);
                                forceUpdate();
                            }}
                        >
                            <ToggleSwitchOnLabel>{`${flag} On`}</ToggleSwitchOnLabel>
                            <ToggleSwitchOffLabel>{`${flag} Off`}</ToggleSwitchOffLabel>
                        </ToggleSwitch>
                    </div>
                );
            })}
        </div>
    );
}

FlagsContents.displayName = "FlagsContents";
