import React, { useState, useEffect } from "react";
import qs from "qs";
import { Typography, Link } from "@vp/swan";
import { getCountry } from "../../../utils/i18n";

interface Props {
    /** The key/sku for the current product */
    productKey: string;
    /** Currently selected product options */
    productOptions: Record<string, string>;

    quantity: number;

    productVersion: number | undefined;
    getCurrentVersion: (productKey: string) => Record<string, any>;
    mcpSku: string;
    locale: string;
}

// @ts-ignore
const host = DESIGN_EXPERIENCE_URL;

export function ProductInformationContents({
    productKey,
    productOptions,
    quantity,
    productVersion,
    getCurrentVersion,
    mcpSku,
    locale
}: Props) {
    const [currentVersion, setCurrentVersion] = useState("");

    useEffect(() => {
        async function retrieveCurrentVersion() {
            try {
                const response = await getCurrentVersion(productKey);
                setCurrentVersion(JSON.stringify(response.version));
            } catch (ex) {
                setCurrentVersion(ex.message);
            }
        }
        if (productKey) {
            retrieveCurrentVersion();
        }
    }, [getCurrentVersion, productKey]);

    const surfacesQueryString = qs.stringify({
        optionSelections: productOptions,
        requestor: "studio-debug-toolbar"
    });

    const configurationOptionsString = qs.stringify({ selectedOptions: JSON.stringify(productOptions) });

    const country = getCountry(locale);

    return (
        <div className="studio-debug-toolbar-expandable-content">
            <div>
                <Typography fontWeight="bold" component="span">{`productKey: `}</Typography>
                <Link
                    href={`https://pim.products.cimpress.io/merchant/products/${productKey}${
                        productVersion && productVersion > 0 ? `/versions/${productVersion}` : ""
                    }`}
                    target="_blank"
                >
                    {productKey}
                </Link>
            </div>
            <div>
                <Typography fontWeight="bold" component="span">
                    {"Surfaces: "}
                </Typography>
                <Link
                    href={`https://surfaces.products.vpsvc.com/surfaces/${productKey}/${productVersion}?${surfacesQueryString}`}
                    target="_blank"
                >
                    Surface Service
                </Link>
            </div>
            <div>
                <Typography fontWeight="bold" component="span">{`Studio Product Version: `}</Typography>
                <Typography component="span" textAlign="right">
                    {productVersion}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" component="span">{`Current Product Version: `}</Typography>
                <Typography component="span" textAlign="right">
                    {currentVersion}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" component="span">{`Quantity: `}</Typography>
                <Typography component="span" textAlign="right">
                    {quantity}
                </Typography>
            </div>
            {Object.keys(productOptions).map(key => {
                return (
                    <div key={key}>
                        <Typography fontWeight="bold" component="span">{`${key}: `}</Typography>
                        <Typography component="span" textAlign="right">
                            {productOptions[key]}
                        </Typography>
                    </div>
                );
            })}
            <div>
                <Typography fontWeight="bold" component="span">{`Studio Configuration: `}</Typography>
                <Link
                    // @ts-ignore
                    href={`${host}/studioConfiguration/product/${productKey}/version/${currentVersion}/resolve?requestor=${REQUESTOR}&${configurationOptionsString}`}
                    target="_blank"
                >
                    Configuration
                </Link>
            </div>
            <div>
                <Typography fontWeight="bold" component="span">{`Stock: `}</Typography>
                <Link
                    href={`https://supplier-inventory-manager.commerce.cimpress.io/mcpInventory?mcpSku=${mcpSku}&countryCode=${country}`}
                    target="_blank"
                >
                    Stock
                </Link>
            </div>
        </div>
    );
}

ProductInformationContents.displayName = "ProductInformationContents";
