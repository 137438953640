import React from "react";
import { BasicCollapsible } from "@vp/swan";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { ABTestContents } from "./ABTestContents";

export interface ABTestsProps {
    /** Array containing the list of test names to display */
    experiments: ExperimentData[];
}

export function ABTests({ experiments }: ABTestsProps) {
    const { isSmall } = useStudioLayout();
    if (!experiments) {
        return null;
    }

    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="abTests" heading="AB Tests">
                    <ABTestContents experiments={experiments} />
                </BasicCollapsible>
            ) : (
                <ExpandingButton content={<ABTestContents experiments={experiments} />} showArrow={false}>
                    <span>AB Tests</span>
                </ExpandingButton>
            )}
        </>
    );
}

ABTests.displayName = "ABTests";
