import React from "react";
import { BasicCollapsible } from "@vp/swan";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { ExternalLinksContents } from "./ExternalLinksContents";

export function ExternalLinks() {
    const { isSmall } = useStudioLayout();

    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="serviceLinks" heading="Service Links">
                    <ExternalLinksContents />
                </BasicCollapsible>
            ) : (
                <ExpandingButton content={<ExternalLinksContents />} showArrow={false}>
                    <span>Service Links</span>
                </ExpandingButton>
            )}
        </>
    );
}

ExternalLinks.displayName = "ExternalLinks";
