import { getQueryParams } from "@shared/utils/WebBrowser";

type LocalData = {
    name: string;
    host: string;
};

export const localeData: Record<string, LocalData> = {
    "en-gb": { name: "Great Britain", host: "www.vistaprint.co.uk" },
    "en-ca": { name: "Canada", host: "www.vistaprint.ca" },
    "fr-ca": { name: "Canada (French)", host: "www.vistaprint.ca/fr" },
    "en-us": { name: "United States", host: "www.vistaprint.com" },
    "es-us": { name: "United States (Spanish)", host: "www.vistaprint.com/es" },
    "en-ie": { name: "Ireland", host: "www.vistaprint.ie" },
    "en-au": { name: "Australia", host: "www.vistaprint.com.au" },
    "en-nz": { name: "New Zealand", host: "www.vistaprint.co.nz" },
    "en-sg": { name: "Singapore", host: "www.vistaprint.sg" },
    "es-es": { name: "Spain", host: "www.vistaprint.es" },
    "it-it": { name: "Italy", host: "www.vistaprint.it" },
    "fr-fr": { name: "France", host: "www.vistaprint.fr" },
    "de-de": { name: "Germany", host: "www.vistaprint.de" },
    "de-at": { name: "Austria (German)", host: "www.vistaprint.at" },
    "de-ch": { name: "Switzerland (German)", host: "www.vistaprint.ch" },
    "de-fr": { name: "Switzerland (French)", host: "www.vistaprint.ch/fr" },
    "it-ch": { name: "Switzerland (Italian)", host: "www.vistaprint.ch/it" },
    "nl-nl": { name: "Netherlands", host: "www.vistaprint.nl" },
    "fr-be": { name: "Belgium (French)", host: "www.vistaprint.be/fr" },
    "nl-be": { name: "Belgium (Dutch)", host: "www.vistaprint.be" },
    "da-dk": { name: "Denmark", host: "www.vistaprint.dk" },
    "pt-pt": { name: "Portugal", host: "www.vistaprint.pt" },
    "sv-se": { name: "Sweden", host: "www.vistaprint.se" },
    "fi-fi": { name: "Finland", host: "www.vistaprint.fi" },
    "nb-no": { name: "Norway", host: "www.vistaprint.no" },
    "en-in": { name: "India", host: "next.vistaprint.in" }
};

/**
 * The various flavors of studio that can be tested
 * five - Studio five
 * six - studio six
 * stable - studio five from before the migration to six began. A stable commit useful only during migration
 */
export type STUDIO_VARIANTS = "five" | "six" | "stable";

export const isLocalHost = /localhost|127\.0\.0\.1|::1/.test(window.location.host);
export const isStaging = window.location.host.includes("staging");
export const isProd = !(isLocalHost || isStaging);

export function setLocaleInQueryParams(url: string, newLocale: string) {
    const paramsRegex = /locale=[a-zA-Z][a-zA-Z]-[a-zA-Z][a-zA-Z]/g;
    if (url.search(paramsRegex) === -1) {
        // Makes assumption that the url already has a search portion on the string
        return `${url}&locale=${newLocale}`;
    }
    return url.replace(paramsRegex, `locale=${newLocale}`);
}

export const LocaleRegex = /\/[a-zA-Z][a-zA-Z]-[a-zA-Z][a-zA-Z]\//g;
export function replaceLocaleInPath(url: string, newLocale: string) {
    const pathRegex = LocaleRegex;
    return url.replace(pathRegex, `/${newLocale}/`);
}

export function getLocalHostLink(locale: string) {
    const params = getQueryParams() as { [key: string]: any };
    let pathLocale = locale.toLowerCase();
    if (params.locale) {
        pathLocale = params.locale.toLowerCase();
    }
    const localUrl = new URL(`http://localhost:3000/studio/five/${pathLocale}/`);
    localUrl.search = window.location.search;
    return localUrl.href;
}

export function getProdLink(locale: string) {
    const host = getProductionHost(locale);
    const productionUrl = new URL(`https://${host}/studio/`);
    productionUrl.search = setLocaleInQueryParams(window.location.search, locale);
    return productionUrl.href;
}

export function getProductionHost(locale: string) {
    const normalizedLocale = locale.toLowerCase();
    return localeData[normalizedLocale]?.host || "www.vistaprint.ie";
}
