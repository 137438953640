import React from "react";
import { Typography, Button } from "@vp/swan";
import AceEditor from "react-ace";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { V2 } from "@shared/utils/DSS";
import { useAppSelector } from "@shared/redux";
import { GetDocument } from "@shared/utils/CimDoc";
import cloneDeep from "lodash/cloneDeep";
import { DocumentLoader } from "../DebugToolbar";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";

export interface DocumentManipulatorProps {
    /** The key/sku for the current product */
    productKey: string;
    /** Currently selected product options */
    productOptions: Record<string, string>;
    productVersion: number | null | undefined;
    /** Loading a cimDoc can take a bit.  Can provide a loader to show in the meantime. */
    showLoader: (show: boolean) => void;
    /** Function used to get a CimDoc from the design  */
    getDocument: GetDocument;
    /** Function used to load the CimDoc currently inside the document from the design  */
    loadDocument: DocumentLoader;
}

export function DocumentManipulator({
    productKey,
    productOptions,
    productVersion,
    showLoader,
    getDocument,
    loadDocument
}: DocumentManipulatorProps) {
    const [value, setValue] = React.useState("");
    const [error, setError] = React.useState(null);
    const { isSmall } = useStudioLayout();
    const studioConfiguration = useAppSelector(state => state.studioConfiguration);
    const reviewInstructions = useAppSelector(state => state.reviewInstructions);
    const locale = useAppSelector(state => state.locale);

    const handleGetDocumentClick = async () => {
        setError(null);
        const docJson = await getDocument();
        const newDocument = cloneDeep(docJson);
        newDocument.metadata = {
            ...newDocument?.metadata,
            reviewInstructions
        };

        setValue(JSON.stringify(newDocument, null, 2));
    };

    if (isSmall || !productVersion) {
        return null;
    }

    return (
        <ExpandingButton
            content={
                <div className="studio-debug-toolbar-expandable-content">
                    <Button size="mini" skin="secondary" onClick={handleGetDocumentClick}>
                        Get Document
                    </Button>
                    <Button
                        size="mini"
                        skin="secondary"
                        onClick={async () => {
                            try {
                                showLoader(true);
                                const views = (
                                    await V2.getDesignViewsForProduct(
                                        productKey,
                                        productVersion,
                                        productOptions,
                                        locale
                                    )
                                ).designViews;
                                await loadDocument({
                                    productKey,
                                    productOptions,
                                    designDocument: JSON.parse(value),
                                    views,
                                    studioConfiguration
                                });
                                setError(null);
                            } catch (ex) {
                                setError(ex.message);
                            } finally {
                                showLoader(false);
                            }
                        }}
                    >
                        Load Document
                    </Button>
                    <Typography color="error">{error}</Typography>
                    <AceEditor
                        className="ace-editor"
                        mode="json"
                        theme="tomorrow"
                        name="cimDocEditor"
                        onChange={e => setValue(e)}
                        onLoad={editor => {
                            // eslint-disable-next-line no-param-reassign
                            editor.container.style.resize = "both";
                            document.addEventListener("mouseup", () => editor.resize());
                        }}
                        fontSize={14}
                        value={value}
                    />
                </div>
            }
            showArrow={false}
        >
            <span>CimDoc</span>
        </ExpandingButton>
    );
}

DocumentManipulator.displayName = "DocumentManipulator";
