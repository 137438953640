import React, { FormEvent } from "react";
import { Typography, Dropdown, DropdownOption } from "@vp/swan";
import { removeQueryParam, windowExists, addQueryParam } from "@shared/utils/WebBrowser";
import { useAbTestContext } from "@shared/features/ABTesting";

interface Props {
    /** Array containing the list of test names to display */
    experiments: ExperimentData[];
}

export function ABTestContents({ experiments }: Props) {
    const abContext = useAbTestContext();
    if (abContext === undefined || !experiments || !windowExists()) {
        return null;
    }
    const { getForcedVariation } = abContext;

    const onChange = (paramName: string, variation: string) => {
        let newUrl = removeQueryParam(window.location.href, paramName);
        localStorage.removeItem(paramName);
        if (variation !== "none") {
            newUrl = addQueryParam(window.location.href, paramName, variation);
            localStorage.setItem(paramName, variation);
        }
        window.location.href = newUrl;
    };

    return (
        <div className="studio-debug-toolbar-expandable-content">
            {experiments.length === 0 && <Typography>No active tests</Typography>}
            {experiments.map(experiment => {
                const { experimentKey, experimentName, variations } = experiment;
                const paramName = `AB_${experimentKey}`;
                const currentValue = getForcedVariation(experimentKey);
                return (
                    <div key={paramName} className="studio-debug-toolbar-flags">
                        <Typography component="span" marginRight={4}>
                            {experimentName}
                        </Typography>
                        <Dropdown
                            value={currentValue}
                            skin="standard"
                            size="mini"
                            // @ts-ignore
                            onChange={(event: FormEvent<HTMLElement>) => onChange(paramName, event.target.value)}
                        >
                            <DropdownOption value="none">{"none"}</DropdownOption>
                            {Object.entries(variations).map(([key, value]) => {
                                return (
                                    <DropdownOption key={key} value={value}>
                                        {key}
                                    </DropdownOption>
                                );
                            })}
                        </Dropdown>
                    </div>
                );
            })}
        </div>
    );
}

ABTestContents.displayName = "ABTestContents";
