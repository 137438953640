import React from "react";
import { Typography, Button, Link, BasicCollapsible, TextArea } from "@vp/swan";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";

export interface AuthInformationProps {
    /** The id of the user */
    owner: string;
    /** Whether user is anonymous */
    isSignedIn: boolean;
    /** Whether user is care agent */
    isCareAgent: boolean;
    auth: any;
}

export function getDesignUI(owner: string) {
    return `https://design-ui.caretech.vpsvc.com/?shopper_id=${owner}`;
}

export function AuthInformation({ owner, isSignedIn, isCareAgent, auth }: AuthInformationProps) {
    const { isSmall } = useStudioLayout();

    const content = (
        <div className="studio-debug-toolbar-expandable-content">
            <div>
                <Typography fontWeight="bold" component="span">{`Owner: `}</Typography>
                <Link target="_blank" href={getDesignUI(owner)}>
                    {owner}
                </Link>
            </div>
            <div>
                <Typography fontWeight="bold" component="span">{`AuthToken: `}</Typography>
                <Typography color="alert">{`Please do not share this token!  It allows others to impersonate you!`}</Typography>
                <TextArea readOnly fullWidth defaultValue={auth && auth.getToken()} />
            </div>
            <div>
                <Typography fontWeight="bold" component="span">{`IsSignedIn: `}</Typography>
                <Typography component="span" textAlign="right">
                    {isSignedIn.toString()}
                </Typography>
            </div>
            <div>
                <Typography fontWeight="bold" component="span">{`IsCareAgent: `}</Typography>
                <Typography component="span" textAlign="right">
                    {isCareAgent.toString()}
                </Typography>
            </div>
            <div>
                <Button
                    size="mini"
                    disabled={!isSignedIn}
                    onClick={async () => {
                        if (auth) {
                            await auth.signOut();
                        }
                    }}
                >
                    Sign out
                </Button>
            </div>
        </div>
    );
    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="auth" heading="Auth">
                    {content}
                </BasicCollapsible>
            ) : (
                <ExpandingButton content={content} showArrow={false}>
                    <span>Auth</span>
                </ExpandingButton>
            )}
        </>
    );
}

AuthInformation.displayName = "AuthInformation";
