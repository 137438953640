import React from "react";
import { Typography, Link, List, ListItem } from "@vp/swan";
import qs from "qs";
import * as styles from "./ValidationsContents.module.scss";

export interface Validation {
    name: string;
    pass: boolean;
    failureInfo: string;
}

interface Props {
    validations: Validation[];
    productKey: string;
    productOptions: Record<string, string>;
    productVersion: number | undefined;
}

export function ValidationsContents({ validations, productKey, productOptions, productVersion }: Props) {
    const passing = validations.filter(v => v.pass);
    const failing = validations.filter(v => !v.pass);
    const surfacesQueryString = qs.stringify({
        optionSelections: productOptions,
        requestor: "studio-debug-toolbar"
    });
    return (
        <div className="studio-debug-toolbar-expandable-content validations-debug-dropdown">
            <Typography fontWeight="bold">Passing</Typography>
            <hr />
            <List skin="checkmark">
                {passing.map(v => (
                    <ListItem key={v.name}>
                        <div className={styles.pass}>{v.name}</div>
                    </ListItem>
                ))}
            </List>
            <Typography fontWeight="bold">Failing</Typography>
            <hr />
            <List>
                {failing.map(v => (
                    <ListItem key={v.name}>
                        <div className={styles.fail}>{v.name}</div>
                        {v.failureInfo}
                    </ListItem>
                ))}
            </List>
            {!failing.length && "No failing validations"}
            <hr />
            <Link
                href="https://vistaprint.atlassian.net/wiki/spaces/DTT/pages/1956709697/WIP+Studio+5+Validations"
                skin="standard"
                target="_blank"
            >
                Validations Documentation
            </Link>
            <br />
            <Link
                href={`https://surfaces.products.vpsvc.com/surfaces/${productKey}/${productVersion}?${surfacesQueryString}`}
                skin="standard"
                target="_blank"
            >
                Surface Service
            </Link>
            <br />
            <Link
                href={`https://studio-calcifer.design.vpsvc.com/v2/upsells?productKey=${productKey}&productVersion=${productVersion}&requestor=studio-debug-toolbar`}
                skin="standard"
                target="_blank"
            >
                Available Upsells
            </Link>
        </div>
    );
}

ValidationsContents.displayName = "ValidationsContents";
