import React from "react";
import { Typography, TextArea } from "@vp/swan";
import AceEditor from "react-ace";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { apiCalls } from "@shared/utils/Network";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";

export function ApiCallHistory() {
    const { isSmall } = useStudioLayout();

    const apiCallsContent = apiCalls.map((call, i) => {
        return (
            <div key={`apicall_${i}`}>
                <ExpandingButton
                    title={call.module}
                    content={
                        <div className="studio-debug-toolbar-expandable-content">
                            <div>
                                <Typography fontWeight="bold" component="span">
                                    URL:
                                </Typography>
                                <TextArea fullWidth readOnly defaultValue={call.url} />
                            </div>
                            {call.method && (
                                <div>
                                    <Typography fontWeight="bold" component="span">{`Method: `}</Typography>
                                    <Typography component="span" textAlign="right">
                                        {call.method}
                                    </Typography>
                                </div>
                            )}
                            <div>
                                <Typography fontWeight="bold" component="span">{`StatusCode: `}</Typography>
                                <Typography component="span" textAlign="right">
                                    {call.statusCode}
                                </Typography>
                            </div>
                            {call.body && (
                                <div>
                                    <Typography fontWeight="bold" component="span">
                                        body:
                                    </Typography>
                                    <AceEditor
                                        className="ace-editor"
                                        readOnly
                                        mode="json"
                                        theme="tomorrow"
                                        name="apiCallBody"
                                        width={"100%"}
                                        onLoad={editor => {
                                            // eslint-disable-next-line no-param-reassign
                                            editor.container.style.resize = "both";
                                            document.addEventListener("mouseup", () => editor.resize());
                                        }}
                                        value={JSON.stringify(JSON.parse(call.body), null, 2)}
                                    />
                                </div>
                            )}
                            <div>
                                <Typography fontWeight="bold" component="span">
                                    Result:
                                </Typography>
                                <AceEditor
                                    className="ace-editor"
                                    readOnly
                                    mode="json"
                                    theme="tomorrow"
                                    name="apiCallResult"
                                    width={"100%"}
                                    fontSize={14}
                                    onLoad={editor => {
                                        // eslint-disable-next-line no-param-reassign
                                        editor.container.style.resize = "both";
                                        document.addEventListener("mouseup", () => editor.resize());
                                    }}
                                    value={
                                        call.result instanceof Error
                                            ? JSON.stringify(JSON.parse(call.result.message), null, 2)
                                            : JSON.stringify(call.result, null, 2)
                                    }
                                />
                            </div>
                        </div>
                    }
                    showArrow={false}
                    overridePopperPlacement={"right"}
                    className="studio-debug-api-call-expanding-button"
                >
                    <Typography color={call.result instanceof Error ? "error" : "standard"}>{call.module}</Typography>
                </ExpandingButton>
            </div>
        );
    });
    return (
        !isSmall && (
            <ExpandingButton
                content={
                    <div className="studio-debug-toolbar-expandable-content studio-debug-toolbar-expandable-content-api">
                        {apiCallsContent}
                    </div>
                }
                showArrow={false}
            >
                <span>API Calls</span>
            </ExpandingButton>
        )
    );
}

ApiCallHistory.displayName = "ApiCallHistory";
