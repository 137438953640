import React, { useRef } from "react";
import { Typography } from "@vp/swan";
import { ExpandingButton, type ExpandingButtonHandles } from "@shared/features/StudioChrome";
import { LocalePulldownList } from "./LocalePulldownList";
import { localeData } from "./LinkUtils";

type Props = {
    onSelect: (value: string) => void;
    value: string;
};

export const LocalePulldown = (props: Props) => {
    const { onSelect, value } = props;
    const expandingButtonRef = useRef<ExpandingButtonHandles>(null);
    const onSelectLocale = (locale: string) => {
        expandingButtonRef?.current?.close();
        onSelect(locale);
    };
    return (
        <ExpandingButton
            ref={expandingButtonRef}
            content={<LocalePulldownList onSelectLocale={onSelectLocale} selectedLocale={value} />}
            showArrow={true}
            displayType="multirow"
        >
            <Typography fontWeight="bold" textAlign="left">
                {value}
            </Typography>
            <Typography fontWeight="bold" textAlign="right">
                {localeData[value].name}
            </Typography>
        </ExpandingButton>
    );
};
LocalePulldown.displayName = "LocalePulldown";
