/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Divider, FlexBox, Icon, Link, Popover, PopoverContent, PopoverLauncher } from "@vp/swan";
import { useAppSelector } from "@shared/redux";
import { TestLink, TestLinkData } from "./TestLinkData";
import {
    getLocalHostLink,
    getProdLink,
    isLocalHost,
    isStaging,
    STUDIO_VARIANTS,
    getProductionHost,
    isProd
} from "./LinkUtils";
import { useRestartUrl } from "./useRestartUrl";
import { LocalePulldown } from "./LocalePulldown";

interface props {
    locale: string;
}
export function TestLinksContent(props: props) {
    const { locale } = props;
    const [ProductTestLinks, setProductTestLinks] = useState<TestLink[]>([]);
    const [selectedLocale, setSelectedLocale] = useState<string>(locale);
    useAppSelector(state => state.easelLoaded);
    useEffect(() => {
        if (selectedLocale) {
            const country = selectedLocale.substring(3).toLowerCase();
            const linksInfo = TestLinkData.filter(item => item.locales.includes(country));
            setProductTestLinks(linksInfo);
        }
    }, [selectedLocale]);

    const restartUrl = useRestartUrl(selectedLocale);

    const buildTestLink = (params: string, targetStudio: STUDIO_VARIANTS) => {
        const currentUrl = new URL(window.location.href);
        currentUrl.search = params;
        currentUrl.searchParams.set("locale", selectedLocale);

        /**
         * URL variations
         * localhost (localhost:3000/studio/five|six/<locale>)
         * staging for features (staging.vpsvc.com/<branchName>/five|six/<locale>)
         * staging for master (staging.five.studio.design.vpsvc.com/studio/five|six/<locale>/)
         * production (vistaprint.TLD/studio5|studio6/)
         * production (vistaprint.TLD/studio) goes to a page which buckets you into 5 or 6
         */
        if (isStaging) {
            const [, branchName] = currentUrl.pathname.split("/"); // pathname always starts with a slash

            // `master` does not have a branch slug, feature branches do
            currentUrl.pathname = branchName.startsWith("studio")
                ? `${branchName}/${targetStudio}/${selectedLocale}`
                : `${branchName}/studio/${targetStudio}/${selectedLocale}`;
        } else if (isLocalHost) {
            currentUrl.pathname = `studio/${targetStudio}/${selectedLocale}`;
        } else {
            currentUrl.pathname = `studio${targetStudio === "five" ? 5 : 6}`;
        }

        // TLD only changes in prod
        if (isProd) {
            currentUrl.host = getProductionHost(selectedLocale);
        }

        return currentUrl.href;
    };

    return (
        <div className="studio-debug-toolbar-expandable-content">
            <LocalePulldown onSelect={setSelectedLocale} value={selectedLocale} />
            <Divider />
            {!isLocalHost && (
                <>
                    <Link href={getLocalHostLink(selectedLocale)}>LocalHost (Current Link)</Link>
                    <br />
                </>
            )}
            {(isLocalHost || isStaging) && (
                <>
                    <Link href={getProdLink(selectedLocale)}>Production (Current Link)</Link>
                    <br />
                    {window.location.pathname.includes("studio/five/") ? (
                        <>
                            <Link href={buildTestLink(window.location.search, "six")}>Goto Studio Six</Link>
                            <br />
                        </>
                    ) : (
                        <>
                            <Link href={buildTestLink(window.location.search, "five")}>Goto Studio Five</Link>
                            <br />
                        </>
                    )}
                </>
            )}
            <>
                <Link href={restartUrl}>Restart Fresh (Current Product and Options)</Link>
                <br />
            </>

            {ProductTestLinks.map(productTestLink => {
                return (
                    <FlexBox justifyContent={"space-between"} key={productTestLink.name}>
                        {productTestLink.name}
                        <FlexBox as={"span"}>
                            <Link ml={2} href={buildTestLink(productTestLink.link, "five")}>
                                Five
                            </Link>
                            <Link ml={2} href={buildTestLink(productTestLink.link, "six")}>
                                Six
                            </Link>
                            {productTestLink.details && (
                                <Popover display={"inline"} ml={2}>
                                    <PopoverLauncher>
                                        <Icon iconType="info" />
                                    </PopoverLauncher>
                                    <PopoverContent>{productTestLink.details}</PopoverContent>
                                </Popover>
                            )}
                        </FlexBox>
                    </FlexBox>
                );
            })}
        </div>
    );
}

TestLinksContent.displayName = "TestLinksContent";
