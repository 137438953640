import React, { useEffect, useState } from "react";
import { BasicCollapsible } from "@vp/swan";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import type { ProductValidationsResponse } from "../clients/spvsClient";
import { Validation, ValidationsContents } from "./ValidationsContents";

export interface ValidationsProps {
    workId: string | null;
    productKey: string;
    productVersion: number | undefined;
    productOptions: Record<string, string>;
    accessToken: string | undefined;
    getCurrentVersion: (productKey: string) => Record<string, any>;
    getProductValidations: (
        productKey: string,
        productVersion: number | undefined,
        selectedOptions: Record<string, string>,
        authToken: string
    ) => Promise<ProductValidationsResponse>;
}

export function Validations({
    workId,
    productKey,
    productVersion,
    productOptions,
    accessToken,
    getCurrentVersion,
    getProductValidations
}: ValidationsProps) {
    const [currentVersion, setCurrentVersion] = useState("");
    const [productValidations, setProductValidations] = useState<ProductValidationsResponse | undefined>(undefined);
    const { isSmall } = useStudioLayout();

    useEffect(() => {
        async function retrieveCurrentVersion() {
            try {
                const response = await getCurrentVersion(productKey);
                setCurrentVersion(JSON.stringify(response.version));
            } catch (ex) {
                setCurrentVersion(ex.message);
            }
        }
        async function retrieveProductValidations() {
            try {
                if (getProductValidations && accessToken) {
                    const productValidationsData = await getProductValidations(
                        productKey,
                        productVersion,
                        productOptions,
                        accessToken
                    );
                    setProductValidations(productValidationsData);
                }
            } catch (ex) {
                setProductValidations(ex.message);
            }
        }

        if (productKey) {
            retrieveCurrentVersion();
            retrieveProductValidations();
        }
    }, [getCurrentVersion, getProductValidations, productKey, productOptions, accessToken, productVersion]);

    const validations = [
        // { name: "Test Failure", pass: false, failureInfo: `Here is some description text on why this failed` },
        // { name: "Test Failure Two", pass: false, failureInfo: `Here is some description text on why this failed` }
    ] as Validation[];

    if (!workId) {
        validations.push({
            name: "Studio Product Version = Current Product Version",
            pass: productVersion === Number(currentVersion),
            failureInfo: `Studio Product Version ${productVersion} | Current Product Version: ${currentVersion}`
        });
    }

    productValidations?.validationBreakdowns?.forEach(validationBreakdowns => {
        validations.push({
            name: validationBreakdowns?.description,
            pass: validationBreakdowns?.status === "pass",
            failureInfo: validationBreakdowns?.impact?.summary
        });
    });

    return (
        <>
            {isSmall ? (
                <BasicCollapsible collapsibleId="validations" heading="Validations">
                    <ValidationsContents
                        validations={validations}
                        productOptions={productOptions}
                        productKey={productKey}
                        productVersion={productVersion}
                    />
                </BasicCollapsible>
            ) : (
                <ExpandingButton
                    content={
                        <ValidationsContents
                            validations={validations}
                            productOptions={productOptions}
                            productKey={productKey}
                            productVersion={productVersion}
                        />
                    }
                    showArrow={false}
                >
                    <span>Validations</span>
                </ExpandingButton>
            )}
        </>
    );
}

Validations.displayName = "Validations";
