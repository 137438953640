import React from "react";
import { Typography, Button } from "@vp/swan";
import AceEditor from "react-ace";
import { ExpandingButton } from "@shared/features/StudioChrome";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";

export interface WorkInformationProps {
    /** get work object */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getWork: () => Record<string, any>;
    /** save work object */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveWork?: (work: Record<string, any>) => void;
    /** get sorted works for user */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getWorks: () => Record<string, any>;
    /** get work revisions for the current work */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getWorkRevisions: () => Record<string, any>;
}

export function WorkInformation({ getWork, saveWork, getWorks, getWorkRevisions }: WorkInformationProps) {
    const [value, setValue] = React.useState("");
    const [error, setError] = React.useState(null);
    const { isSmall } = useStudioLayout();

    return (
        !isSmall && (
            <ExpandingButton
                content={
                    <div className="studio-debug-toolbar-expandable-content">
                        <Button
                            size="mini"
                            onClick={async () => {
                                try {
                                    setValue(JSON.stringify(await getWork(), null, 2));
                                    setError(null);
                                } catch (ex) {
                                    setError(ex.message);
                                }
                            }}
                        >
                            Get Work
                        </Button>
                        {saveWork && (
                            <Button
                                size="mini"
                                onClick={async () => {
                                    try {
                                        await saveWork(JSON.parse(value));
                                        setError(null);
                                    } catch (ex) {
                                        setError(ex.message);
                                    }
                                }}
                            >
                                Save Work
                            </Button>
                        )}
                        <Button
                            size="mini"
                            onClick={async () => {
                                try {
                                    setValue(JSON.stringify(await getWorks(), null, 2));
                                    setError(null);
                                } catch (ex) {
                                    setError(ex.message);
                                }
                            }}
                        >
                            Get Works
                        </Button>
                        <Button
                            size="mini"
                            onClick={async () => {
                                try {
                                    setValue(JSON.stringify(await getWorkRevisions(), null, 2));
                                    setError(null);
                                } catch (ex) {
                                    setError(ex.message);
                                }
                            }}
                        >
                            Work Revisions
                        </Button>
                        <div>
                            <Typography color="error" component="span">
                                {error}
                            </Typography>
                        </div>
                        <div>
                            <Typography fontWeight="bold" component="span">{`Work: `}</Typography>
                            <AceEditor
                                className="ace-editor"
                                mode="json"
                                theme="tomorrow"
                                name="workEditor"
                                width={"100%"}
                                onChange={e => setValue(e)}
                                onLoad={editor => {
                                    // eslint-disable-next-line no-param-reassign
                                    editor.container.style.resize = "both";
                                    document.addEventListener("mouseup", () => editor.resize());
                                }}
                                fontSize={14}
                                value={value}
                            />
                        </div>
                        <Typography color="alert">
                            The document is not saved as part of modifying the work here, only the work itself.
                        </Typography>
                        <Typography color="alert">Saving may trigger a page reload.</Typography>
                    </div>
                }
                showArrow={false}
            >
                <span>Work</span>
            </ExpandingButton>
        )
    );
}

WorkInformation.displayName = "WorkInformation";
